import { Box, Flex, Image, Text } from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";

const About = () => {

  const moonRef = useRef(null);
  const studioRef = useRef(null);


  return (
    <Flex className="about" fontSize={["xs", "sm", "md"]} gap={10}>
      <Box>
        <Text fontSize={["sm", "md", "lg"]} color={"lightBlue"} mb={2}>
          MY CODING JOURNEY
        </Text>
        <Box>
          My journey into coding began in 2018 while working as an Assistant
          ITPM. Managing web development projects and collaborating with
          developers sparked my fascination with how lines of code turn ideas
          into functional tools. This interest led me to enroll in web
          development courses, where I gained a deeper understanding of creating
          engaging web experiences. Upon completing my courses, I started
          freelancing, focusing on building e-commerce websites for small to
          medium-sized businesses. Each project allowed me to apply my new
          skills, from integrating APIs to designing responsive, user-friendly
          interfaces.
          <br />
          As my expertise grew, I began working as a full-time frontend
          developer in 2021. Now, with years of hands-on experience in web
          development technologies, I have a deep understanding of computer
          science fundamentals, strong proficiency in UI/UX design principles,
          and expertise in developing scalable and dynamic web applications. I
          am adept at translating design mockups from Figma into efficient,
          clean code that not only meets but exceeds performance standards.
          <br />A fun fact about me is that, besides my engineering background,
          I also hold a master’s degree in fashion design. I enjoy bringing my
          design ideas to life not only on the screen but also in tangible forms
          like clothes and accessories. Having studied both engineering and
          applied arts has provided me with a unique blend of technical
          expertise and design sensibility, allowing me to approach frontend
          development from both a practical and aesthetic standpoint. Minimal on
          the surface yet maximal in functionality are the designs I most enjoy
          working on.
        </Box>
      </Box>
      <Box>
        <Text fontSize={["sm", "md", "lg"]} color={"lightBlue"} mb={2}>
          THROUGH MY EYES
        </Text>
        <Flex flexDirection={["column", "column", "row"]} gap={5} ref={moonRef}>
          <Image
            src="./moon.jpg"
            width={["100%", "100%", "50%"]}
            // opacity={moonOpacity}
          />
          <Box>
            Photography has been a lifelong escape for me. Through the lens, I
            explore different perspectives, play with light and shadow, and
            document moments that inspire and evoke emotion. <br /> This passion
            for photography enhances my attention to detail and my appreciation
            for aesthetics, both of which are crucial in my approach to design
            and development.
          </Box>
        </Flex>
      </Box>
      <Box>
        <Text fontSize={["sm", "md", "lg"]} color={"lightBlue"} mb={2}>
          MY FAVORITE CORNER
        </Text>
        <Flex
          flexDirection={["column", "column", "row"]}
          alignContent={""}
          gap={5}
          ref={studioRef}
        >
          <Box>
            {" "}
            When I'm not at the computer, you can find me in my studio, behind
            my sewing machine, indulging in my obsession with design and
            creation.
            <br /> I love experimenting with fabrics, patterns, and textures,
            bringing my ideas to life through detailed craftsmanship and
            innovative designs. This hands-on approach to creativity not only
            fuels my passion but also inspires my work in the digital realm.
          </Box>
          <Image
            src="./nealphi_grayscale.jpg"
            width={["100%", "100%", "50%"]}
            // opacity={studioOpacity}
          />
        </Flex>
      </Box>
    </Flex>
  );
};

export default About;
