import { Box } from "@chakra-ui/react";
import "./App.css";
import MainContainer from "./components/MainContainer";
import CoinFlipModal from "./components/CoinFlipModal";


function App() {
  return (
    <Box className="App" >
     <MainContainer />
    
    </Box>
  );
}

export default App;
