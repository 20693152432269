import React from "react";
import ProjectLayout from "./ProjectLayout";
import { Flex, Grid, GridItem, Image, Text } from "@chakra-ui/react";

const Projects = () => {
  return (
    <Flex
      className="projects"
      flexDirection={["column"]}
      gap={[5, 10]}
      px={["5%", "15%"]}
    >
      <Text
        fontSize={["md", "lg"]}
        as={"b"}
        color={"lightBlue"}
        borderBottom={"2px"}
      >
        SAMPLE PROJECTS
      </Text>{" "}
      <ProjectLayout
        href="https://booking-app-xgfy.onrender.com/"
        title={"Hotel-Booking Application"}
      >
        Hotel Booking App is a comprehensive, full-stack MERN application
        designed to streamline the hotel booking experience. The app is powered
        by a secure and robust user authentication system that employs HTTP
        cookies and JWT, ensuring a seamless and protected login and
        registration process. With advanced Hotel Management features, users can
        effortlessly add, edit, and view hotel listings, while Image Uploads are
        seamlessly handled via Cloudinary, ensuring that each hotel listing is
        visually appealing with high-quality images. To further enhance user
        experience, the app includes intuitive Search, Sort, and Filter
        functionalities, making it easy for users to find their ideal stay based
        on preferences like location, price, and amenities. Online Payments are
        securely managed through Stripe, providing a hassle-free and efficient
        way for users to book hotels directly within the app. Additionally, the
        Booking Management feature allows users to view and manage their
        bookings, making the app a complete solution for both hotel owners and
        guests. The app is deployed on Render.com, ensuring a reliable and
        scalable environment for users to explore and book their perfect
        accommodations.
        <Grid templateColumns={"repeat(3, 1fr)"} gap={[2, 5]} mt={[5, 10]}>
          <GridItem>
            <Image borderRadius={5} src="/hotel-booking-1.jpg" />
          </GridItem>
          <GridItem>
            <Image borderRadius={5} src="/hotel-booking-2.jpg" />
          </GridItem>
          <GridItem>
            <Image borderRadius={5} src="/hotel-booking-3.jpg" />
          </GridItem>
          <GridItem>
            <Image borderRadius={5} src="/hotel-booking-4.jpg" />
          </GridItem>
          <GridItem>
            <Image borderRadius={5} src="/hotel-booking-6.jpg" />
          </GridItem>
          <GridItem>
            <Image borderRadius={5} src="/hotel-booking-5.jpg" />
          </GridItem>
        </Grid>
      </ProjectLayout>
      <ProjectLayout
        href="https://nealphi-ecommerce.vercel.app/"
        title={"MERN Stack E-Commerce Project"}
      >
        The NEALPHI demo website, built with the MERN stack, offers a
        user-friendly experience featuring registration, login, and secure
        authentication. Each user receives a credited amount for exploring
        purchases. The site includes a wallet, an account page for profile
        updates and password changes, a shopping cart, and a purchase history
        section. This demo effectively showcases NEALPHI's potential as an
        online platform, blending functionality with the brand's dedication to
        quality and craftsmanship.
        <Grid templateColumns={"repeat(3, 1fr)"} gap={[2, 5]} mt={[5, 10]}>
          <GridItem>
            <Image borderRadius={[5, 10]} src="/mernproject1.png" />
          </GridItem>
          <GridItem>
            <Image borderRadius={[5, 10]} src="/mernproject2.png" />
          </GridItem>
          <GridItem>
            <Image borderRadius={[5, 10]} src="/mernproject3.png" />
          </GridItem>
          <GridItem>
            <Image borderRadius={[5, 10]} src="/mernproject5.png" />
          </GridItem>
          <GridItem>
            <Image borderRadius={[5, 10]} src="/mernproject4.png" />
          </GridItem>

          <GridItem>
            <Image borderRadius={[5, 10]} src="/mernproject6.png" />
          </GridItem>
        </Grid>
      </ProjectLayout>
      {/* <ProjectLayout
        href="https://game-hub-liart-sigma.vercel.app/"
        title={"Game-Hub Project"}
      >
        A video game discovery web application that helps you find new and
        interesting games to play. With GameHub, you can search for games by
        platform, genre, and more. Built with vite.js, TypeScript and Chakra UI,
        using RAWG API for fetching the Games.
      </ProjectLayout> */}
      <ProjectLayout
        href="https://www.neginalipanahi.com/"
        title={"Personal Website Project"}
      >
        Designed and developed a personal website using React.js that is fully
        responsive, ensuring optimal display on various devices. The site
        features a clean and modern look achieved with Chakra UI and Bootstrap
        for styling. It also includes interactive animations created with
        Spline, enhancing user engagement and providing a dynamic browsing
        experience.
      </ProjectLayout>
      {/* <ProjectLayout
        href="https://weatherforcastapplication.vercel.app/"
        title={"Weather Forecast App"}
      >
        Designed and developed a weather forecast application using React.js,
        enhanced with interactive 3D animations created in Spline. The
        application provides a visually engaging and dynamic user experience,
        and ensures reliable and comprehensive weather data for various
        locations by integrating the OpenWeather API.
        <Grid
        templateColumns={"repeat(4, 1fr)"}
        gap={5}
        mt={[5,10]}
      >
        <GridItem>
          <Image borderRadius={10} src="/weatherproject1.png" />
        </GridItem>
        <GridItem>
          <Image borderRadius={10} src="/weatherproject3.png" />
        </GridItem>
        <GridItem>
          <Image borderRadius={10} src="/weatherproject2.png" />
        </GridItem>
      
        <GridItem>
          <Image borderRadius={10} src="/weatherproject4.png" />
        </GridItem>
      </Grid>
      </ProjectLayout> */}
      
      {/* <ProjectLayout
        href="https://neginalipanahi-portfolio.vercel.app/"
        title={"Portfolio Project"}
      >
        Inspired by Brittany Chiang's portfolio design, I developed my own using
        Visual Studio Code. Built with Vite.js and styled with Chakra UI, it
        features animations from Framer Motion. Deployed on Vercel for a smooth
        and reliable experience.
      </ProjectLayout> */}
    </Flex>
  );
};

export default Projects;
