import React from "react";
import Work from "./Work";
import { Box, Grid, Link, Text } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import Education from "./Education";

const Resume = () => {
  return (
    <Box className="resume" gap={10} px={["10%", "15%"]}>
      <Link
        fontSize={"lg"}
        color={"gray.100"}
        href="./Negin Alipanahi_Resume.pdf"
        isExternal
        _hover={{ color: "darkBlue" }}
        fontWeight={"bold"}
      >
        View Full Résumé <ExternalLinkIcon mx="2px" />
      </Link>
      <Text
        fontSize={["md", "lg"]}
        as={"b"}
        color={"darkBlue"}
        borderBottom={"2px"}
      >
        PROFESSIONAL EXPERIENCE
      </Text>
      <Work
        company={"Pelak, Tehran, Iran"}
        title={"Front-end Web Developer"}
        date={"Mar 2021 - May 2024"}
      >
        <Box fontSize={["xs", "sm", "md"]}>
          • Developed highly customizable components using React.js and
          TypeScript, resulting in a 30% reduction in development time for new
          features. <br /> • Achieved a 20% increase in mobile traffic by
          implementing cutting-edge responsive designs, ensuring optimal user
          engagement across all devices. <br /> • Refactored and optimized the
          existing codebase for the task posting feature and developed a task
          scheduling feature with a calendar view, streamlining user interaction
          and leading to a 15% increase in client acquisition on the platform.{" "}
          <br /> • Coordinated with cross-functional teams to consistently
          achieve and surpass project milestones and deadlines, ensuring timely
          and successful project completion. <br /> • Implemented accessibility
          standards, improving the platform's usability score by 40%, ensuring
          an inclusive user experience. <br />
          <b>
            Skills: HTML5 - CSS3 - JavaScript - ES6 - TypeScript - React.js -
            React Hooks - React Query - React Router - Redux - Babel - Node.js -
            Express.js - RESTful APIs - Storybook - Git
          </b>
        </Box>
      </Work>
      <Work
        company={"Freelance, Tehran, Iran"}
        title={"Full-stack Web Developer"}
        date={"Sep 2019 - Mar 2021"}
      >
        <Box fontSize={["xs", "sm", "md"]}>
          • Developed and deployed responsive e-commerce websites using the MERN
          stack for small to medium-sized businesses.
          <br />• Integrated RESTful APIs for product management, user
          authentication, and payment processing.
          <br />• Enhanced performance and SEO with server-side rendering (SSR)
          using Next.js.<br/>
          <b>Skills: Next.js - React.js - Node.js - Express.js - RESTful APIs - MongoDB</b>
        </Box>
      </Work>

      <Work
        company={"Golandishe IT-company, Tehran, Iran "}
        title={"Assistant ITPM"}
        date={"Aug 2014 - Mar 2018"}
      >
        <Box fontSize={["xs", "sm", "md"]}>
          • Analyzed web development workflows, creating detailed process maps
          and WBS. <br />• Collaborated in crafting comprehensive project
          roadmaps, defining sprint timelines, resource allocation, and ensuring
          alignment with Agile methodologies to meet project milestones. <br />•
          Generated project status reports, incorporating Gantt charts and
          burn-down charts to provide visibility on development progress and
          potential bottlenecks. <br />• Streamlined cross-functional
          communication to ensure consistent updates on code commits and bug
          fixes, while maintaining transparency with stakeholders on project
          scope and timelines.
          <br />
          <b>
            Skills: SDLC - Agile Methodologies (Scrum) - MS Project - Primavera
            P6
          </b>
        </Box>
      </Work>

      <Text
        fontSize={["md", "lg"]}
        as={"b"}
        color={"darkBlue"}
        borderBottom={"2px"}
      >
        EDUCATION
      </Text>
      <Education
        major={"MA Applied Arts"}
        institute={"Tehran University of Art"}
      />
      <Education
        major={
          "M.Sc. Industrial Engineering - System Management and Productivity"
        }
        institute={"IAU-TNB"}
      />
      <Education
        major={"B.Sc. Industrial Engineering - Planning and System Analysis"}
        institute={"IAU-TNB"}
      />
    </Box>
  );
};

export default Resume;
