import React from 'react'
import Spline from "@splinetool/react-spline";


const SplineAnimation = () => {
  return (
    <div className='spline'>
  <Spline
        scene="https://prod.spline.design/XBZu442xC3Huw5tV/scene.splinecode" 
      />
    </div>
  )
}

export default SplineAnimation